.fixed-icons {
  position: fixed;
  top: 95%;
  transform: translateY(-50%);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2%;
}

.left-icon {
  background-color: #e2e2e2;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(226, 226, 226, 0.5); /* 50% opacity */
}

.right-icon {
  background-color: #25d366; /* WhatsApp green color */
  padding: 10px;
  border-radius: 10%;
  background-color: rgba(37, 211, 102, 0.8); /* 80% opacity */
}

.right-icon a {
  color: white;
  text-decoration: none;
}

.right-icon:hover {
  background-color: #1e865f; /* WhatsApp green hover color */
}
